<template>
  <div class="video-box">
    <div class="video_playIcon" v-if="!initVideo.play" @click="playVideo">
    </div>

    </video>
    <div class="video_control">
        <div class="progress">
            <el-slider class="commonSlider" :show-tooltip="false" v-model="initVideo.currentTime":max="initVideo.videoLength" @change="changeVideoTime">
            </el-slider>
            <div class="time">
                <span>{{initVideo.currentTime | videoTime}}</span>
                <span>{{initVideo.videoLength | videoTime}}</span>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
// import Watcher from "../../../webSocket/watcher";
export default {
  name: "trailerModal",
  props: {
    // videoUrl: {
    //   type: String,
    //   default: "",
    // },
  },
  data() {
    return {
            //视频
            initVideo: {
                play: false,//播放还是暂停 true播放中
                videoLength: 0,//时长
                url: "",//视频课件Url
                currentTime: 0,//当前播放时间
                lastTime: null,//标记时间戳
                name: "",
            },
            //音频
            initAudio: {
                play: false,//播放还是暂停 true播放中
                audioLength: 0,//audio时长
                url: "",//音频课件
                urlcurrentTime: 0,//当前播放时间
                lastTime: null,//标记时间戳
                name: "",
            },
            videoTime:0,
        }
    },
    methods: {
        playVideo() {
            //播放视频
            if (this.initVideo.play) {
                this.$refs.video.pause();
            } else {
                Math.abs(this.initVideo.currentTime - this.$refs.video.currentTime) > 2 ? this.$refs.video.currentTime = this.initVideo.currentTime : '';
                this.$refs.video.play();
                this.$refs.video.muted = false
            }
        },
        videoTimeUpdate() {
            //更新视频时间。节流，每秒触发一次
            let nowTime = Date.now()
            let gapTime = 1000;
            if (!this.initVideo.lastTime || nowTime - this.initVideo.lastTime > gapTime) {
                if (this.$refs.video) {
                    let time = this.$refs.video.currentTime;
                    this.initVideo.currentTime = time;
                }
                this.initVideo.lastTime = nowTime
            }
        },
        changeVideoTime(val) {
            //改变视频时间
            console.log(val, 'video')
            if (this.$refs.video) {
                this.$refs.video.currentTime = val;
            }
        },
        getAudioLength(type) {
            //获取音视频时长 1音频 2视频
            if (type == 1) {
                this.initAudio.audioLength = this.$refs.audio.durationconsole.log('获取音频时长', this.$refs.audio.duration)
            }
            if (type == 2) {
                this.initVideo.videoLength = this.$refs.video.durationconsole.log('获取视频时长', this.$refs.video.duration)
            }
        },
        handPlay(type, status) {
            //音视频播放暂停 type:1 音频 2视频 status 1播放 2暂停
            if (type == 1) {
                status == 1 ? this.initAudio.play = true : this.initAudio.play = false;
            } else {
                status == 1 ? this.initVideo.play = true : this.initVideo.play = false;
            }
        },
  }
  
  
 
};
</script>

<style lang="scss">
.trailer_video_box {
   width:100%;
   height: 100%;
  height: calc(100% - 100px);
  position: absolute;
  top: 8px;
  left: 0;
  z-index: 1000;
  background: #292c32;
  border-radius: 12px;
  .trailer_box {
    width:100%;
   height: 100%;
    height: calc(100% - 143px);
    padding: 0;
    object-fit: initial;
    border-radius: 12px 12px 0 0;
  }
  .trailer_progress_bar {
    width:100%;
   height: 100%;
    overflow: hidden;
    position: relative;
    .proress_title {
      width:100%;
  
      height: 100px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
         width:32px;
        height: 32px;
        margin-right: 8px;
      }
      p {
        color: #fff;
        font-size: 12px;
        font-weight: 600;
      }
    }
    .proress_content_box {
      width: 100%;
      overflow: hidden;
      display: flex;
      padding: 0 24px;
      justify-content: center;
      p {
        color: #fff;
        font-weight: 400;
        font-size: 12px;
      }
      .pro_pone {
      }
      .progress_bar_box {
        flex: 1;
        margin: 6px 16px 0 16px;
        height: 8px;
        background: rgba(205, 206, 209, 0.2);
        border-radius: 5px;
        box-sizing: border-box;
        position: relative;
        .on_progress_bar_child {
          width: 0;
          height: 100%;
          background: #cdced1;
          border-radius: 4px 0px 0px 4px;
          // overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1001;
          .mouse_down {
            position: absolute;
            width: 5px;
            height: 16px;
            top: 50%;
            margin-top: -8px;
            left: 100%;
            background: #ff5001;
            border-radius: 3px;
            z-index: 1002;
            cursor: pointer;
          }
        }
        .on_progress_bar_child.border_Radius {
          border-radius: 4px;
        }
      }
    }
  }
}
</style>